/*------------------------------------
  Nav
------------------------------------*/

// Subtitle
.nav-subtitle {
  display: block;
  color: $nav-subtitle-color;
  font-size: $nav-subtitle-font-size;
  text-transform: uppercase;
  letter-spacing: $nav-subtitle-letter-spacing;
}

// Nav Link
.nav-link.dropdown-toggle {
  display: flex;
  align-items: center;
}

.nav-link.active {
  color: $nav-link-active-color;
}

// Link SVG
.nav-link.active .nav-link-svg path,
.nav-item.show .nav-link-svg path {
  fill: $nav-tabs-link-active-color;
}

// Title
.nav-title:last-child,
.nav-text:last-child {
  margin-bottom: 0;
}

// Icon
.nav-icon {
  font-size: $nav-icon-font-size;
  opacity: $nav-icon-opacity;
  margin-right: $nav-icon-margin-x;
}

// Nav Divider
.nav-divider {
  height: $nav-divider-height;
  border-left: $nav-divider-border-width solid $nav-divider-border-color;
  margin: 0 $nav-divider-margin-x;
}

// Gray
.nav.nav-link-gray {
  .nav-link {
    color: $nav-gray-link-color;

    &.active,
    &:hover {
      color: $nav-gray-link-active-color;
    }

    &.disabled {
      color: $nav-gray-link-disabled-color;
    }
  }
}

// Tabs
.nav-tabs {
  .nav-link {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: $nav-tabs-border-width solid transparent;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    font-weight: $nav-tabs-font-weight;
  }

  &:not(.nav-vertical) {
    .nav-link {
      padding-top: $nav-tabs-link-padding-y;
      padding-bottom: $nav-tabs-link-padding-y;
    }
  }
}

// Vertical
.nav-vertical {
  flex-direction: column;

  &.nav .nav-item:not(:last-child) {
    margin-right: 0;
  }

  .nav-subtitle {
    padding-left: $nav-link-padding-x;
  }

  &.nav-tabs {
    border-bottom-width: 0;

    .nav-link {
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
      margin-bottom: 0;
    }

    .nav-subtitle {
      border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: $nav-tabs-link-active-border-color;
    }
  }

  &.nav.nav-tabs .nav-collapse {
    .nav-link {
      padding-left: $navbar-vertical-nav-link-padding-x * 2;
    }
  }
}

// Collapse
.nav-collapse {
  .nav-link {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      left: $nav-collapse-link-pseudo-left-offset;
      transform: translateY(-50%);
      content: $nav-collapse-link-pseudo-content;
    }
  }
}

// Pills
.nav-pills {
  .nav-item {
    margin: $nav-pills-item-margin-y $nav-pills-item-margin-x;
  }

  .nav-link {
    padding: $nav-pills-padding-y $nav-pills-padding-x;

    &:hover {
      color: $nav-pills-link-hover-color;
      background-color: $nav-pills-link-hover-bg-color;
    }

    &.active:hover {
      background-color: $nav-pills-link-active-bg;
    }
  }

  .nav-link.active:hover,
  .show>.nav-link:hover {
    border-color: transparent;
  }
}

// Segment
.nav-segment {
  position: relative;
  background-color: $nav-segment-bg-color;
  padding: $nav-segment-padding-y $nav-segment-padding-x;
  @include border-radius($nav-segment-border-radius);

  &:not(.nav-fill) {
    display: inline-flex;
  }

  .nav-link {
    color: $nav-segment-link-color;
    font-size: $nav-segment-font-size;
    padding: $nav-segment-link-padding-y $nav-segment-link-padding-x;
    @include border-radius($nav-segment-link-border-radius);

    &:hover {
      color: $nav-segment-link-hover-color;
    }

    &.active {
      color: $nav-segment-link-active-color;
      background-color: $nav-segment-link-active-bg-color;
      box-shadow: $nav-segment-link-active-box-shadow;
    }
  }

  &.nav-pills {
    @include border-radius($nav-pills-segment-border-radius);

    .nav-link {
      @include border-radius($nav-pills-segment-link-border-radius);
    }
  }
}

// Sizes
.nav-sm {
  .nav-link {
    font-size: $nav-sm-link-font-size;
  }
}

.nav-lg {
  .nav-link {
    font-size: $nav-lg-link-font-size;
  }
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 140%;
  background-color: rgba(0, 0, 0, 0.5);
}

#ebook-download {
  display: none;
  position: fixed;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32.5vw;
  height: 70vh;  
  background-color: transparent;
  z-index: 1;
}

#ebook-iframe{

  -ms-transform: scale(0.87);
  -moz-transform: scale(0.87);
  -o-transform: scale(0.87);
  -webkit-transform: scale(0.87);
  transform: scale(0.87);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

#ebook-download-iframe {
  width: 100%;
  height: 100%;
}

#feature-suggest {
  display: none;
  position: fixed;
  top: 58%;
  left: 88%;
  transform: translate(-50%, -50%);
  width: 33vw;
  height: 68vh;
  overflow-y: scroll;
  background-color: transparent;
  z-index: 1;
}

#feature-suggest-iframe {
  width: 45%;
  height: 100%;
  overflow: scroll;
}

.sidebar {
  height: 100%;
  width: 55vh;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  right: 0;
  border: 1px solid rgb(0, 0, 0) !important;
}


.sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.content-details {
  margin-top: 2vh;
  background-color: white;
  height: 30%;
  width: 47vh;
  margin-left: 24px;
  border-radius: 25px;
}

.blue-chip {
  display: inline-block;
  padding: 0 25px;
  height: 28px;
  font-size: 5px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #16326e;
  margin-left: 10px;
}

.popup-container {
  background-color: #16326e;
  height: 99px;
  position: sticky;
  top: 0;
  z-index: 10
}

.popup-header {
  height: 49px;
  margin-left: 19px;
  padding-top: 17px;
  display: flex;
  justify-content: space-between;
}

.suggest-feature-background {
  height: 49px;
  margin-left: 36px;
  display: flex;
  justify-content: space-between;
}

#popup-feature {
  display: flex;
  justify-content: space-between
}