// Front Default Color Variables
// =========================================================

$root-path: "../" !default;

// Grays
$gray-100: #f9fafc;
$gray-200: #f8fafd;
$gray-300: #e7eaf3;
$gray-400: #bdc5d1;
$gray-500: #97a4af;
$gray-600: #8c98a4;
$gray-700: #677788;
$gray-800: #51596c;
$gray-900: #223b41;

// Colors
$white: #fff;
$dark: #341131;
$green: #008060;
$teal: #077c76;
$blue: #1421d8;
$yellow: #f1b980;
$red: #692340;

// Theme Colors
$primary: #03045e;
$primary-light: lighten($primary, 12%) !default;
$primary-dark: darken($primary, 7%);
$secondary: #00b4d8;
$success: $teal;
$info: $blue;
$warning: $yellow;
$danger: $red;
$dark: $dark;

// Theme Soft Colors
$primary-soft: rgba($green, 0.1) !default;
$primary-light-soft: rgba(lighten($primary, 12%), 0.1) !default;
$secondary-soft: rgba($gray-800, 0.1) !default;
$success-soft: rgba($teal, 0.1) !default;
$info-soft: rgba($blue, 0.1) !default;
$warning-soft: rgba($yellow, 0.1) !default;
$danger-soft: rgba($red, 0.1) !default;
$light-soft: rgba($gray-100, 0.1) !default;
$dark-soft: rgba($dark, 0.1) !default;
