// Bootstrap Customs
// =========================================================

$font-family-base: "Manrope", sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs: 0.75rem !default;
$font-size-sm: 0.8125rem;
$font-size-lg: 1.125rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700 #{/* rtl:600 */};

$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.125;
$h4-font-size: $font-size-base * 1.625;
$h5-font-size: $font-size-base * 1.375;
$h6-font-size: $font-size-base * 1.125;

$headings-font-weight: $font-weight-medium;
$headings-color: $dark;

$display-font-sizes: (
  1: 6.25rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.75rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$display-font-weight: $font-weight-bold;

$lead-color: $gray-900 !default;
$lead-font-size: $font-size-base * 1.5;
$lead-font-weight: 400;

// Colors
$body-color: $gray-800;
$link-color: $primary;
$link-hover-color: $primary-dark;
$active-color: $link-color !default;
$disabled-color: $gray-400 !default;
$text-muted: $gray-600 !default;

// Links
$link-decoration: none;
$link-darker: $primary-dark !default;
$link-bordered-border-width: 0.125rem !default;
$link-bordered-border-color: $link-color !default;
$link-hover-opacity: 0.7 !default;
$link-sm-font-size: $font-size-base * 0.875 !default;

// Backgrounds
$light-bg-color: $gray-300 !default;
$overlay-soft-bg-color: rgba($dark, 0.25) !default;
$secondary-bg-color: transparent !default;
$active-bg-color: $primary !default;
$disabled-bg-color: rgba($gray-300, 0.5) !default;
$primary-soft-bg-color: $primary-light-soft !default;

// Shadows
$box-shadow: 0rem 0.375rem 1.5rem 0rem rgba($gray-600, 0.125);
$box-shadow-sm: 0rem 0.375rem 0.75rem rgba($gray-600, 0.075);
$box-shadow-lg: 0rem 0.6125rem 2.5rem 0.6125rem rgba($gray-600, 0.175);
$box-shadow-xl: 0rem 3.75rem 5rem 0rem rgba($gray-600, 0.3) !default;

// Borders
$border-width: 0.0625rem;
$border-color: rgba($dark, 0.1);

// Border Radius
$border-radius: 0.3125rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: $border-radius;
$border-radius-pill: 6.1875rem !default;
$border-radius-rounded: 50% !default;

// Transitions
$transition-timing: 0.3s !default;
$transition-timing-sm: 0.2s !default;
$transition-timing-md: 0.4s !default;
$transition-function: ease-in-out !default;

// Others
$link-font-weight: $font-weight-medium !default;

$letter-spacing: 0.03125rem !default;

$hr-color: $border-color;
$hr-opacity: 1;

$code-font-size: 1em;

$kbd-color: $headings-color;
$kbd-bg: $light-bg-color;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 4.5,
);

// Content Spacer
$content-spacer: 1rem !default;
$content-spacers: (
  0: 0,
  1: (
    $content-spacer * 4,
  ),
  2: (
    $content-spacer * 5,
  ),
  3: (
    $content-spacer * 7.5,
  ),
  4: (
    $content-spacer * 10,
  ),
) !default;

// Number of horizontally stacked cards in one line
$card-group-number-of-cards: 4 !default;

// Z-index
$z-indexes: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
) !default;

// Grid containers
$container-max-widths: (
  md: 1100px,
  xxl: 1140px,
);

// Color system
$theme-soft-colors: (
  "primary": $primary-soft,
  "secondary": $secondary-soft,
  "success": $success-soft,
  "info": $info-soft,
  "warning": $warning-soft,
  "danger": $danger-soft,
  "light": $light-soft,
  "dark": $dark-soft,
) !default;

// Options
// Quickly modify global styling by enabling or disabling optional features.
// =========================================================

$enable-caret: false;
$enable-shadows: false;
$enable-rounded: true;
$enable-rfs: true;
$enable-validation-icons: false;
$enable-smooth-scroll: false;

// Gradients
// =========================================================

$gradient-transparent: rgba($white, 0) !default;
$gradient-white: $white !default;
$gradient-dark: $dark !default;
$gradient-primary: $primary !default;
$gradient-info: $info !default;
$gradient-success: $success !default;
$gradient-danger: $danger !default;
$gradient-warning: $warning !default;
$gradient-primary-soft: $primary-soft-bg-color !default;

// Scrollbar
// =========================================================

// Width & Heights
$scrollbar-width: 0.6125rem !default;
$scrollbar-horizontal-height: $scrollbar-width !default;

// Background Colors
$scrollbar-bg-color: rgba($gray-400, 0.6) !default;
$scrollbar-horizontal-bg-color: $scrollbar-bg-color !default;

// Button + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// =========================================================

$input-btn-focus-width: 0;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($gray-600, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 1rem;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color;

$input-btn-padding-y: 0.6125rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.875rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

// Card
// =========================================================

// Backgrounds
$card-cap-bg: transparent;
$card-bg: $white;

// Borders
$card-border-color: $border-color;
$card-cap-border-width: 0 !default;
$card-border-radius: $border-radius;

// Paddings
$card-spacer-y: 1.25rem;
$card-spacer-x: $card-spacer-y;
$card-spacer-y-xs: 0.5rem;
$card-spacer-x-xs: $card-spacer-y-xs;
$card-spacer-y-sm: 1rem;
$card-spacer-x-sm: $card-spacer-y-sm;
$card-spacer-y-lg: 2rem;
$card-spacer-x-lg: $card-spacer-y-lg;
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-cap-padding-y;

// Type
$card-link-font-weight: $font-weight-medium !default;

// Positions
$card-pinned-top-offset: 1rem !default;
$card-pinned-bottom-offset: $card-pinned-top-offset !default;
$card-pinned-left-offset: $card-pinned-top-offset !default;
$card-pinned-right-offset: $card-pinned-top-offset !default;

// Margins
$card-title-spacer-y: 0.25rem;
$card-title-margin-y: 0.5rem !default;

// Shadows
$card-box-shadow: $box-shadow !default;

// Transitions
$card-transition: all $transition-timing-sm $transition-function !default;
$card-transition-translateY: -0.1875rem !default;

// Forms
// =========================================================

// Colors
$input-color: $body-color;
$input-placeholder-color: $gray-600;
$input-focus-color: $input-color;
$input-focus-border-color: $input-btn-focus-color;
$input-group-addon-color: $gray-600;
$form-label-secondary-color: $gray-600 !default;
$form-check-label-color: $body-color;
$form-check-custom-input-checked-color: $white !default;
$form-select-indicator-color: $gray-800;

// Background
$input-group-addon-bg: $white;
$form-switch-input-bg-color: $light-bg-color !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>");
$form-check-input-checked-bg-color: $primary;
$form-check-custom-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$form-check-custom-input-checked-color}'/></svg>") !default;
$form-check-selectable-bg-active-color: rgba($primary, 0.05) !default;
$input-card-bg-color: $card-bg !default;
$input-card-form-bg-color: $border-color !default;

// Borders
$input-border-color: $border-color;
$input-border-width: $border-width;
$input-group-text-border-width: 0 !default;
$inpu-card-border-radius: $card-border-radius !default;
$input-card-border-width: 0 !default;
$form-check-input-border: 1px solid $input-border-color;
$form-switch-input-border-width: 0 !default;

// Allows for customizing button radius independently from global border radius
$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius;
$input-border-radius-lg: $border-radius;

// Type
$form-label-font-weight: $font-weight-medium !default;
$form-label-font-size: $font-size-base * 0.875 !default;

// Width & Heights
$form-switch-input-width: 3em !default;
$form-switch-input-height: 2em !default;
$input-card-form-width: $border-width !default;
$input-card-form-height: 2rem !default;
$form-switch-promotion-min-width: 5rem !default;

// Positions
$form-switch-promotion-container-position-top-offset: 1.25rem !default;
$form-switch-promotion-container-position-left-offset: 1.25rem !default;

// Padding
$input-card-padding-y: 0.75rem !default;
$input-card-padding-x: $input-card-padding-y !default;
$input-card-form-padding: 1rem !default;

// Margin
$form-label-margin-bottom: 0.5rem;
$form-text-margin-top: 1rem;
$form-switch-margin-x: 0.5rem !default;
$form-switch-between-margin-x: $form-switch-margin-x;
$form-feedback-margin-top: 0.25rem;
$form-switch-promotion-body-margin-y: 1rem;
$form-switch-promotion-arrow-margin-x: 1.75rem;
$form-switch-promotion-text-margin-y: 0.3125rem;

// Shadows
$input-card-box-shadow: $card-box-shadow !default;

$input-focus-width: $input-btn-focus-width;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 $input-btn-focus-blur
  $form-select-focus-width $input-btn-focus-color;

// Others
$form-check-input-active-filter: 100%;

// Buttons
// =========================================================

// Color
$btn-white-hover-color: $link-hover-color !default;
$btn-white-disabled-color: $disabled-color !default;

// Backgrounds
$btn-ghost-bg-color: $secondary-bg-color !default;
$btn-white-bg-color: $white !default;
$btn-white-bg-disabled-color: $disabled-bg-color !default;

// Borders
$btn-border-radius: $input-border-radius;
$btn-border-radius-sm: $input-border-radius-sm;
$btn-border-radius-lg: $input-border-radius-lg;
$btn-outline-secondary-color: $input-border-color !default;
$btn-white-border-color: $btn-outline-secondary-color !default;

// Shadows
$btn-box-shadow-value: 0 4px 11px !default;
$btn-white-box-shadow-hover: 0px 3px 6px -2px rgba($gray-600, 0.25) !default;

// Transitions
$btn-transition: all $transition-timing-sm $transition-function !default;

// Button Icon
// =========================================================

// Type
$btn-icon-font-weight: $font-weight-medium !default;
$btn-icon-font-size: $font-size-base !default;
$btn-icon-font-size-xs: $font-size-xs !default;
$btn-icon-font-size-sm: $font-size-base * 0.8125 !default;
$btn-icon-font-size-lg: $font-size-lg !default;
$btn-status-font-size: $font-size-base * 0.625 !default;
$btn-status-line-height: 1 !default;

// Width & Heights
$btn-icon-width: $font-size-base * 2.875 !default;
$btn-icon-width-xs: $font-size-base * 1.75 !default;
$btn-icon-width-sm: $font-size-base * 2.3125 !default;
$btn-icon-width-lg: $font-size-base * 4.25 !default;
$btn-status-width: $font-size-base * 1.3125 !default;

$btn-icon-height: $btn-icon-width !default;
$btn-icon-height-xs: $btn-icon-width-xs !default;
$btn-icon-height-sm: $btn-icon-width-sm !default;
$btn-icon-height-lg: $btn-icon-width-lg !default;
$btn-status-height: $btn-status-width !default;

// Avatar
// =========================================================

// Width & Heights
$avatar-width: $btn-icon-width !default;
$avatar-width-xss: 1rem !default;
$avatar-width-xs: $btn-icon-width-xs !default;
$avatar-width-sm: $btn-icon-width-sm !default;
$avatar-width-lg: $btn-icon-width-lg !default;
$avatar-width-xl: $font-size-base * 5.625 !default;
$avatar-width-xxl: $font-size-base * 7 !default;
$avatar-status-width: $btn-status-width !default;
$avatar-status-width-sm: $font-size-base * 0.9375 !default;
$avatar-status-width-lg: $font-size-base * 1.625 !default;

$avatar-height: $btn-icon-width !default;
$avatar-height-xss: $avatar-width-xss !default;
$avatar-height-xs: $avatar-width-xs !default;
$avatar-height-sm: $avatar-width-sm !default;
$avatar-height-lg: $avatar-width-lg !default;
$avatar-height-xl: $avatar-width-xl !default;
$avatar-height-xxl: $avatar-width-xxl !default;
$avatar-status-height: $avatar-status-width !default;
$avatar-status-height-sm: $avatar-status-width-sm !default;
$avatar-status-height-lg: $avatar-status-width-lg !default;

// Backgrounds
$avatar-bg-color: $white !default;

// Border
$avatar-img-border-radius: $border-radius !default;
$avatar-circle-border-radius: $border-radius-rounded !default;
$avatar-border-width: 2px !default;
$avatar-border-color: $white !default;
$avatar-border-width-lg: 5px !default;
$avatar-border-color-lg: $white !default;
$avatar-border-width-xl: 7px !default;
$avatar-border-color-xl: $white !default;
$avatar-status-border-radius: $avatar-circle-border-radius !default;
$avatar-status-border-width: 0.125rem !default;
$avatar-status-border-color: $white !default;

// Z-index
$avatar-group-z-index: 2 !default;

// Type
$avatar-font-size-xss: $btn-icon-font-size-xs !default;
$avatar-font-size-xs: $btn-icon-font-size-xs !default;
$avatar-font-size: $btn-icon-font-size !default;
$avatar-font-size-sm: $btn-icon-font-size-sm !default;
$avatar-font-size-lg: $btn-icon-font-size-lg !default;
$avatar-font-size-xl: $font-size-base * 2 !default;
$avatar-font-size-xxl: $font-size-base * 3 !default;
$avatar-font-weight: $btn-icon-font-weight !default;
$avatar-status-font-size: $btn-status-font-size !default;
$avatar-status-line-height: $btn-status-line-height !default;
$avatar-status-font-size-sm: $font-size-base * 0.5 !default;
$avatar-status-font-size-lg: $font-size-base * 0.75 !default;

// Margins
$avatar-group-margin-x-xs: -0.6125rem !default;
$avatar-group-margin-x: -1rem !default;
$avatar-group-margin-x-sm: -0.875rem !default;
$avatar-group-margin-x-lg: -1.5rem !default;
$avatar-group-margin-x-xl: -2rem !default;

// Nav
// =========================================================

// Colors
$nav-link-color: $dark;
$nav-link-active-color: $active-color !default;
$nav-subtitle-color: $text-muted !default;
$nav-link-hover-color: $link-hover-color;
$nav-tabs-link-active-color: $active-color;
$nav-pills-link-active-color: initial !default;
$nav-pills-link-hover-color: $dark !default;
$nav-segment-link-color: $body-color !default;
$nav-segment-link-active-color: $headings-color !default;
$nav-segment-link-active-hover-color: $white !default;
$nav-segment-link-hover-color: $primary !default;
$nav-gray-link-color: $gray-600 !default;
$nav-gray-link-active-color: $active-color !default;
$nav-gray-link-disabled-color: $disabled-color !default;

// Backgrounds
$nav-tabs-link-active-bg: transparent;
$nav-pills-link-active-bg: $primary-soft-bg-color;
$nav-pills-link-hover-bg-color: $gray-200 !default;
$nav-segment-bg-color: $gray-200 !default;
$nav-segment-link-active-bg-color: $white !default;

// Type
$nav-subtitle-font-size: $font-size-xs !default;
$nav-subtitle-letter-spacing: $letter-spacing !default;
$nav-icon-font-size: 1.125rem !default;
$nav-icon-opacity: 0.7 !default;
$nav-tabs-font-weight: $font-weight-medium !default;
$nav-segment-font-size: $font-size-sm !default;
$nav-sm-link-font-size: 0.9375rem !default;
$nav-lg-link-font-size: $h5-font-size !default;
$nav-collapse-link-pseudo-content: "•" !default;

// Borders
$nav-divider-border-width: 0.1rem !default;
$nav-divider-border-color: $border-color !default;
$nav-tabs-border-width: 0.1875rem;
$nav-tabs-border-color: $border-color;
$nav-tabs-link-hover-border-color: $border-color;
$nav-tabs-link-active-border-color: $active-color;
$nav-tabs-border-radius: 0;
$nav-segment-border-radius: $border-radius !default;
$nav-segment-link-border-radius: $nav-segment-border-radius !default;
$nav-pills-segment-border-radius: $border-radius-pill !default;
$nav-pills-segment-link-border-radius: $nav-pills-segment-border-radius !default;

// Width & Heights
$nav-divider-height: 2rem !default;
$nav-divider-height-in-navbar-expand-down: 0.0625rem !default;

// Shadows
$nav-segment-link-active-box-shadow: 0rem 0.1875rem 0.375rem 0rem
  rgba($gray-600, 0.25) !default;

// Padding
$nav-link-padding-x: 1rem;
$nav-pills-padding-y: 1rem !default;
$nav-pills-padding-x: $nav-pills-padding-y !default;
$nav-pills-item-margin-y: 0.25rem !default;
$nav-pills-item-margin-x: 0.25rem !default;
$nav-tabs-link-padding-y: 1.25rem !default;
$nav-segment-padding-y: 0.25rem !default;
$nav-segment-padding-x: $nav-segment-padding-y !default;
$nav-segment-link-padding-y: $input-btn-padding-y-sm !default;
$nav-segment-link-padding-x: $input-btn-padding-x-sm !default;

// Margin
$nav-divider-margin-x: 0.5rem !default;
$nav-icon-margin-x: 0.5rem !default;

// Gap
$navbar-nav-wrap-secondary-content-gap: 0.5rem !default;

// Positions
$nav-collapse-link-pseudo-left-offset: $nav-link-padding-x * 2 !default;

// Nav Scroller
// =========================================================

// Colors
$nav-scroller-arrow-link-color: $body-color !default;
$nav-scroller-arrow-link-hover-color: $link-hover-color !default;
$nav-scroller-arrow-light-link-color: rgba($white, 0.7) !default;
$nav-scroller-arrow-light-link-hover-color: $white !default;

// Width & Heights
$nav-scroller-arrow-link-width: $btn-icon-width !default;

// Z-index
$nav-scroller-arrow-z-index: 1 !default;

// Type
$nav-scroller-arrow-font-size: $font-size-base * 1.5 !default;

// Margins
$nav-scroller-arrow-margin-x: 0.125rem !default;

// Navbar
// =========================================================

// Colors
$navbar-light-brand-color: $body-color;
$navbar-light-toggle-color: $gray-600 !default;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $active-color;
$navbar-dark-hover-color: $white;
$navbar-dark-divider-color: rgba($white, 0.2) !default;
$navbar-dropdown-menu-media-title-color: $gray-900 !default;
$navbar-dropdown-menu-media-desc-color: $body-color !default;
$navbar-dropdown-menu-media-title-hover-color: $link-color !default;
$navbar-dropdown-menu-promo-link-title-active-color: $navbar-dropdown-menu-media-title-hover-color !default;

// Backgrounds
$navbar-bg-color: $white !default;
$navbar-dark-bg-color: $primary-dark !default;
$navbar-toggle-bg-hover-color: darken($gray-200, 2%) !default;
$navbar-dark-scrolled-bg-color: $primary-dark !default;
$navbar-light-scrolled-bg-color: $white !default;
$navbar-dropdown-menu-media-link-bg-hover-color: rgba($gray-400, 0.3) !default;
$navbar-dropdown-menu-promo-link-bg-active-color: $navbar-dropdown-menu-media-link-bg-hover-color !default;

// Borders
$navbar-dropdown-menu-link-border-radius: $border-radius !default;
$navbar-vertical-nav-tabs-border-color: transparent !default;
$navbar-light-toggle-border-color: $border-color !default;
$navbar-dropdown-menu-border-width: $border-width !default;
$navbar-dropdown-menu-border-color: $border-color !default;
$navbar-dropdown-menu-promo-item-border-width: $border-width !default;
$navbar-dropdown-menu-promo-item-border-color: $border-color !default;
$navbar-sidebar-aside-brand-wrapper-border-width: $border-width !default;
$navbar-sidebar-aside-brand-wrapper-border-color: $border-color !default;

// Width & Heights
$navbar-height: 3.875rem !default;
$navbar-brand-default-width: 8.5rem !default;
$navbar-absolute-height: 75vh !default;
$navbar-sticky-height: 75vh !default;
$navbar-sidebar-aside-width: 17rem !default;
$navbar-sidebar-aside-brand-height: 5rem !default;

// Type
$navbar-dropdown-menu-media-title-font-weight: $font-weight-medium !default;
$navbar-dropdown-menu-media-desc-font-size: $font-size-base * 0.875 !default;

// Z-index
$navbar-z-index: 99 !default;
$navbar-sidebar-aside-z-index: $navbar-z-index !default;

// Box Shadows
$navbar-box-shadow: $box-shadow !default;
$navbar-light-scrolled-box-shadow: $box-shadow !default;

// Positions
$navbar-dropdown-menu-pseudo-top-offset: 0.625rem !default;

// Paddings
$navbar-toggler-padding-y: 0.5rem;
$navbar-toggler-padding-x: $navbar-toggler-padding-y;
$navbar-nav-padding-y: 1rem !default;
$navbar-nav-padding-x: $navbar-nav-padding-y !default;
$navbar-nav-link-padding-y: 1rem !default;
$navbar-nav-link-padding-x: 0.75rem !default;
$navbar-vertical-nav-link-padding-y: 0.3125rem !default;
$navbar-vertical-nav-link-padding-x: $nav-link-padding-x * 1.5 !default;
$navbar-dropdown-menu-inner-padding-y: 0.75rem !default;
$navbar-dropdown-menu-inner-padding-x: $navbar-dropdown-menu-inner-padding-y !default;
$navbar-dropdown-menu-media-link-padding-y: 1rem !default;
$navbar-dropdown-menu-media-link-padding-x: $navbar-dropdown-menu-media-link-padding-y !default;
$navbar-dropdown-menu-promo-link-padding-y: $navbar-dropdown-menu-media-link-padding-y !default;
$navbar-dropdown-menu-promo-link-padding-x: $navbar-dropdown-menu-promo-link-padding-y !default;
$navbar-sidebar-aside-content-padding-x: 0.75rem !default;
$navbar-sidebar-aside-brand-padding-y: 1.25rem !default;
$navbar-sidebar-aside-brand-padding-x: 1.5rem !default;

// Margins
$navbar-dropdown-menu-margin-y: 0.5rem !default;
$navbar-dropdown-menu-link-margin-y: 0.5rem !default;
$navbar-nav-item-margin-x: 0.5rem !default;
$navbar-dropdown-menu-promo-item-margin-y: $navbar-dropdown-menu-inner-padding-y !default;
$navbar-dropdown-menu-promo-item-margin-x: $navbar-dropdown-menu-promo-item-margin-y !default;
$navbar-dropdown-menu-inner-menu-margin-y: 0.6875rem !default;
$navbar-dropdown-menu-inner-menu-margin-x: 0.5rem !default;

// Others
$navbar-fix-effect-transition: $transition-timing ease !default;

// Drop Down
// =========================================================

// Colors
$dropdown-color: $body-color;
$dropdown-link-active-color: $headings-color;
$dropdown-icon-color: $body-color !default;

// Backgrounds
$dropdown-link-hover-bg: rgba($gray-400, 0.3);
$dropdown-link-active-bg: $dropdown-link-hover-bg;
$dropdown-overlay-bg-color: $overlay-soft-bg-color !default;
$dropdown-divider-bg: $light-bg-color;

// Width & Heights
$dropdown-icon-width: 1.5rem !default;
$dropdown-toggle-pseudo-width: 1rem !default;
$dropdown-toggle-pseudo-height: 1rem !default;
$navbar-dropdown-menu-pseudo-height: 1.75rem !default;

// Type
$dropdown-icon-font-size: $font-size-lg !default;
$dropdown-item-font-size: $font-size-base * 0.875 !default;
$dropdown-header-font-size: $nav-subtitle-font-size !default;
$dropdown-toggle-pseudo-bg: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$dropdown-color}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;
$dropdown-toggle-pseudo-light-bg: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{rgba($white, .55)}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;
$dropdown-toggle-link-pseudo-bg: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$link-color}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;

// Borders
$dropdown-border-width: 0;
$dropdown-item-border-radius: $border-radius !default;

// Z-index
$dropdown-overlay-z-index: $navbar-z-index + 1 !default;

// Paddings
$dropdown-padding-x: 0.5rem;
$dropdown-padding-y: 0.5rem;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;
$dropdown-header-padding: 0.5rem 1rem;
$dropdown-toggle-padding-x: 1.25rem !default;

// Margin
$dropdown-spacer: 0.625rem !default;
$dropdown-item-margin-y: 0.25rem !default;
$dropdown-margin-y: 0.5rem !default;

// Shadows
$dropdown-box-shadow: $box-shadow-lg !default;

// Transition
$dropdown-overlay-transition: $transition-timing-sm ease !default;
$dropdown-toggle-arrow-transition: $transition-timing !default;
$dropdown-toggle-arrow-collapse-rotation: rotate(-180deg) !default;

// Others
$dropdown-icon-opacity: 0.7 !default;

// Accordion
// =========================================================

// Colors
$accordion-button-color: $dark !default;
$accordion-icon-color: $dark;
$accordion-icon-active-color: $active-color;

// Backgrounds
$accordion-button-active-bg: $primary-soft-bg-color;
$accordion-border-color: $border-color;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E");

// Borders
$accordion-border-color: $border-color;

// Type
$accordion-button-font-size: $h6-font-size !default;
$accordion-button-font-weight: $font-weight-medium !default;

// Padding
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 2rem;

// Transition
$accordion-icon-transform: rotate(0deg);

// Badge
// =========================================================

// Type
$badge-font-weight: $font-weight-medium;

// Text
// =========================================================

// Colors
$text-cap-color: $dark !default;

// Type
$text-cap-font-size: $font-size-base * 0.75 !default;
$text-cap-font-weight: $font-weight-medium !default;
$text-cap-letter-spacing: $letter-spacing !default;

// Margin
$text-cap-margin-y: 1rem !default;

// Blockquote
// =========================================================

// Color
$blockquote-color: $dark !default;
$blockquote-footer-color: $dark;
$blockquote-footer-source-color: $gray-700 !default;

// Type
$blockquote-font-size: 1rem;
$blockquote-sm-font-size: 0.9rem;
$blockquote-footer-font-size: 1.25rem;
$blockquote-footer-font-weight: $font-weight-medium !default;
$blockquote-footer-source-font-size: $font-size-base !default;
$blockquote-footer-source-font-weight: $font-weight-normal !default;

// Margin
$blockquote-margin-y: 0;
$blockquote-footer-margin-y: 1.5rem !default;
$blockquote-footer-source-margin-y: 0.25rem !default;

// Lists
// =========================================================

// Color
$list-separator-link-color: $body-color !default;
$list-separator-link-hover-color: $link-hover-color !default;
$list-separator-light-link-color: $white !default;

// Backgrounds
$list-group-striped-bg-color: rgba($warning, 0.1) !default;

// Borders
$list-group-border-color: $gray-300;
$list-comment-item-border-left-width: 0.1875rem !default;
$list-comment-item-border-left-color: $border-color !default;

// Width & Heights
$list-group-icon-width: 1.25rem !default;
$list-group-lg-icon-width: $avatar-width-sm !default;

// Type
$list-group-sm-item-font-size: $font-size-sm !default;
$list-group-lg-icon-font-size: $font-size-base * 1.5 !default;

// Paddings
$list-group-lg-item-padding-y: 1.5rem !default;
$list-group-lg-item-padding-x: 1.5rem !default;
$list-padding-y-1: 0.25rem !default;
$list-padding-y-2: 0.5rem !default;
$list-padding-y-3: 1rem !default;
$list-separator-padding-x: 2rem !default;
$list-comment-item-padding-x: 1rem !default;

// Margin
$list-group-icon-margin-x: 0.25rem !default;
$list-group-lg-icon-margin-x: 0.5rem !default;
$list-separator-item-margin-x: 0.25rem !default;
$list-comment-margin-y: 2.5rem !default;

// Others
$list-separator-pseudo-content: "•" !default;
$list-separator-pseudo-opacity: 0.5 !default;

// List Checked
// =========================================================

// Colors
$list-checked-item-color: $body-color !default;
$list-checked-item-hover-color: $link-color !default;

// Width & Heights
$list-checked-pseudo-width: 1.25rem !default;
$list-checked-pseudo-height: 1.25rem !default;
$list-checked-sm-pseudo-width: 1rem !default;
$list-checked-sm-pseudo-height: 1rem !default;
$list-checked-lg-pseudo-width: 1.75rem !default;
$list-checked-lg-pseudo-height: 1.75rem !default;

// Others
$list-checked-pseudo-soft-bg-opacity-value: 0.1 !default;

// Type
$list-checked-pseudo: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$form-select-indicator-color}'/></svg>") !default;

// Paddings
$list-checked-item-padding-x: $list-checked-pseudo-width + 0.75rem !default;
$list-checked-sm-item-padding-x: $list-checked-sm-pseudo-width + 0.5rem !default;
$list-checked-lg-item-padding-x: $list-checked-lg-pseudo-width + 1rem !default;

// Margins
$list-checked-item-margin-y: 0.5rem !default;
$list-checked-lg-item-margin-y: 1.25rem !default;
$list-checked-pseudo-margin-y: 0.125rem !default;
$list-checked-bg-pseudo-margin-y: 0.25rem !default;
$list-checked-sm-pseudo-margin-y: 0.3125rem !default;
$list-checked-sm-bg-pseudo-margin-y: 0.25rem !default;
$list-checked-lg-pseudo-margin-y: 0 !default;
$list-checked-lg-bg-pseudo-margin-y: 0.0625rem !default;

// Lines
// =========================================================

// Borders
$lines-border-width: $border-width !default;
$lines-border-color: $border-color !default;
$lines-light-border-color: rgba($white, 0.1) !default;

// Width & Heights
$lines-container-max-width: 1160px !default;
$lines-item-width: $border-width !default;

// Modals
// =========================================================

// Backgrounds
$modal-backdrop-bg: $overlay-soft-bg-color;

// Borders
$modal-content-border-color: transparent;
$modal-header-border-width: 0;

// Paddings
$modal-inner-padding: 1.5rem;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: $modal-inner-padding;
$modal-footer-padding-y: $modal-header-padding-y !default;
$modal-footer-padding-x: $modal-header-padding-x !default;
$modal-close-padding-y: 0.25rem !default;
$modal-close-padding-x: $modal-close-padding-y !default;

// Width & Heights
$modal-md: 550px;
$modal-sm: 400px;

// Z-index
$zindex-modal-backdrop: $navbar-z-index + 1;
$zindex-modal: $zindex-modal-backdrop + 1;

// Position Spaces
$modal-top-cover-close-top-offset: 0.75rem !default;
$modal-top-cover-close-right-offset: $modal-top-cover-close-top-offset !default;

// Type
$modal-footer-text-font-size: $font-size-sm !default;

// Others
$modal-backdrop-opacity: 1;
$modal-top-cover-close-z-index: 2 !default;

// Step
// =========================================================

// Colors
$step-title-color: $headings-color !default;
$step-text-color: $body-color !default;
$step-active-icon-color: $white !default;
$step-is-valid-color: $primary !default;
$step-is-valid-icon-color: $white !default;
$step-is-invalid-color: $danger !default;
$step-is-invalid-icon-color: $white !default;

// Background
$step-icon-pseudo-bg-color: $gray-500 !default;
$step-active-icon-bg-color: $active-bg-color !default;
$step-is-valid-icon-bg-color: $step-is-valid-color !default;
$step-is-invalid-icon-bg-color: $step-is-invalid-color !default;

// Borders
$step-border-width: 0.125rem !default;
$step-border-color: $border-color !default;
$step-icon-border-radius: $border-radius-rounded !default;
$step-icon-pseudo-border-radius: $border-radius-rounded !default;
$step-avatar-border-radius: $step-icon-border-radius !default;

// Width & Height
$step-icon-width: $btn-icon-width !default;
$step-icon-height: $step-icon-width !default;
$step-icon-width-xs: $btn-icon-width-xs !default;
$step-icon-height-xs: $step-icon-width-xs !default;
$step-icon-width-sm: $btn-icon-width-sm !default;
$step-icon-height-sm: $step-icon-width-sm !default;
$step-icon-width-lg: $font-size-base * 5.5 !default;
$step-icon-height-lg: $step-icon-width-lg !default;

$step-avatar-width: $step-icon-width !default;
$step-avatar-height: $step-avatar-width !default;
$step-avatar-width-xs: $step-icon-width-xs !default;
$step-avatar-height-xs: $step-avatar-width-xs !default;
$step-avatar-width-sm: $step-icon-width-sm !default;
$step-avatar-height-sm: $step-avatar-width-sm !default;
$step-avatar-width-lg: $step-icon-width-lg !default;
$step-avatar-height-lg: $step-avatar-width-lg !default;

$step-icon-pseudo-width: 0.25rem !default;
$step-icon-pseudo-height: $step-icon-pseudo-width !default;

$step-divider-height: 1rem !default;

// Type
$step-icon-font-size: $btn-icon-font-size !default;
$step-icon-font-weight: $font-weight-medium !default;

$step-icon-font-size-xs: $btn-icon-font-size-xs !default;
$step-icon-font-size-sm: $btn-icon-font-size-sm !default;
$step-icon-font-size-lg: $btn-icon-font-size-lg !default;

$step-title-font-weight: $font-weight-medium !default;

$step-avatar-font-weight: $step-icon-font-weight !default;
$step-avatar-font-size: $step-icon-font-size !default;
$step-avatar-font-size-xs: $step-icon-font-size-xs !default;
$step-avatar-font-size-sm: $step-icon-font-size-sm !default;
$step-avatar-font-size-lg: $step-icon-font-size-lg !default;

$step-divider-font-weight: $font-weight-medium !default;

// Paddings
$step-padding-x: 1.5rem !default;
$step-padding-y: $step-padding-x !default;

// Margins
$step-item-margin-y: 2.25rem !default;
$step-icon-margin-y: 1rem !default;
$step-icon-margin-x: 1rem !default;
$step-avatar-margin-x: $step-icon-margin-x !default;
$step-timeline-content-margin-x: 1.5rem !default;
$step-timeline-icon-margin-x: $step-icon-width / 2 !default;
$step-timeline-icon-xs-margin-x: $step-icon-width-xs / 2 !default;
$step-timeline-icon-sm-margin-x: $step-icon-width-sm / 2 !default;
$step-timeline-icon-lg-margin-x: $step-icon-width-lg / 2 !default;

// SVG Icon
// =========================================================

// Width & Heights
$svg-icon-width: 3rem !default;
$svg-icon-height: $svg-icon-width !default;
$svg-icon-xs-width: 1.25rem !default;
$svg-icon-xs-height: $svg-icon-xs-width !default;
$svg-icon-sm-width: 1.75rem !default;
$svg-icon-sm-height: $svg-icon-sm-width !default;
$svg-icon-lg-width: 4rem !default;
$svg-icon-lg-height: $svg-icon-lg-width !default;

// Margins
$svg-icon-margin-y: 0.125rem !default;

// Breadcrumbs
// =========================================================

// Colors
$breadcrumb-item-color: $headings-color !default;

// Backgrounds
$breadcrumb-bg: transparent;

// Borders
$breadcrumb-divider-color: $gray-500;

// Pagination
// =========================================================

// Colors
$pagination-color: $gray-700;

// Borders
$pagination-border-radius: $border-radius-sm;

// Paddings
$pagination-padding-x-lg: 1.25rem;

// Popover
// =========================================================

// Background
$popover-header-bg: $gray-200;

// Border
$popover-border-color: transparent;
$popover-arrow-color: $white;
$popover-arrow-outer-color: $popover-border-color;

// Type
$popover-font-size: $font-size-base;
$popover-header-font-weight: $font-weight-medium !default;

// Box Shadow
$popover-box-shadow: $box-shadow-lg;

// Paddings
$popover-body-padding-y: 1.5rem;
$popover-body-padding-x: $popover-body-padding-y;
$popover-header-padding-x: $popover-body-padding-x;
$popover-header-padding-y: 1rem;

// Progress
// =========================================================

// Background
$progress-bg: $gray-300;
$progress-vertical-bg-color: $progress-bg !default;

// Width & Heights
$progress-height: 0.5rem;
$progress-vertical-width: 0.5rem !default;
$progress-vertical-height: 12.5rem !default;

// Table
// =========================================================

// Colors
$table-thead-th-color: $headings-color !default;
$table-body-color: $dark !default;

// Background
$table-thead-light-bg: $gray-200;
$table-striped-bg: $gray-100;
$table-hover-bg: rgba($gray-300, 0.4);

// Borders
$table-border-color: $border-color;
$table-thead-border-color: $table-border-color !default;

// Type
$table-th-font-weight: $font-weight-normal !default;
$table-thead-th-font-weight: $font-weight-medium !default;
$table-sm-font-size: $font-size-sm !default;

// Padding
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;
$table-cell-padding-y-lg: 1rem;
$table-cell-padding-x-lg: 1.5rem;

// Toast State
// =========================================================

// Borders
$toast-border-color: $border-color;
$toast-border-radius: $card-border-radius;

// Box Shadow
$toast-box-shadow: $box-shadow-sm;

// Paddings
$toast-padding-y: 1rem;
$toast-padding-x: $toast-padding-y;

// Tooltips
// =========================================================

// Backgrounds
$tooltip-bg: $dark;

// Others
$tooltip-opacity: 1;

// Column Divider
// =========================================================

// Borders
$col-divider-width: $border-width !default;
$col-divider-bg-color: $border-color !default;
$col-divider-light-bg-color: rgba($white, 0.1) !default;

// Paddings
$col-divider-padding-y: 1.5rem !default;

// Divider
// =========================================================

// Colors
$divider-color: $text-muted !default;

// Borders
$divider-border-width: $border-width !default;
$divider-border-color: $border-color !default;

// Margins
$divider-margin-x: 1.5rem !default;

// Devices
// =========================================================

// Backgrounds
$device-mobile-bg-color: $gray-200 !default;
$device-browser-bg-color: $white !default;
$device-browser-header-bg-color: $white !default;
$device-browser-header-btn-list-btn-bg-color: $gray-300 !default;
$device-browser-header-browser-bar-bg-color: $gray-300 !default;

// Borders
$device-mobile-border-radius: 2rem !default;
$device-browser-border-radius: 0.5rem !default;
$device-browser-header-border-width: $border-width !default;
$device-browser-header-border-color: lighten($gray-300, 3%) !default;
$device-browser-header-btn-list-btn-border-radius: $border-radius-rounded !default;
$device-browser-header-browser-bar-border-radius: 0.125rem !default;

// Width & Heights
$devices-max-width: 1140px !default;
$device-mobile-width: 15rem !default;
$device-vertical-tablet-width: 30rem !default;
$device-browser-width: 50rem !default;
$device-browser-lg-width: 60rem !default;
$device-browser-header-btn-list-btn-width: 0.5rem !default;
$device-browser-header-btn-list-btn-height: $device-browser-header-btn-list-btn-width !default;

// Shadows
$device-mobile-box-shadow: 0 2.75rem 5.5rem -3.5rem rgba($gray-400, 0.5),
  0 2rem 4rem -2rem rgba($dark, 0.3),
  inset 0 -0.1875rem 0.3125rem 0 rgba($gray-500, 0.5) !default;
$device-browser-box-shadow: 0 2.75rem 5.5rem -1rem rgba($gray-400, 0.5),
  0 2rem 4rem -1rem rgba($dark, 0.15) !default;

// Type
$device-browser-header-browser-bar-font-size: $font-size-base * 0.5 !default;

// Positions
$device-browser-header-btn-list-btn-start-offset: 1rem !default;

// Margins
$devices-margin-y: 5rem !default;
$devices-margin-x: 0.75rem !default;
$devices-margin-x-offset: 5rem !default;

// Paddings
$devices-padding-y: $devices-margin-y + 2rem !default;
$device-mobile-padding: 0.3125rem !default;
$device-browser-header-padding-y: 0.5rem !default;
$device-browser-header-padding-x: 6.5rem !default;

// Margins
$devices-mobile-margin-x: 0.5rem !default;

// Gap
$device-browser-header-btn-list-btn-gap: 0.25rem !default;

// Code Editor
// =========================================================

// Colors
$code-editor-color: $body-color !default;
$code-editor-line-numbers-color: $gray-600 !default;

// Backgrounds
$code-editor-line-numbers-bg-color: rgba($warning, 0.1) !default;
$code-editor-bg-color: $white !default;
$code-editor-typing-area-divider-bg-color: $border-color !default;

// Borders
$code-editor-border-radius: $border-radius !default;

// Width & Heights
$code-editor-line-numbers-min-width: 3rem !default;
$code-editor-typing-area-divider-width: $border-width !default;
$code-editor-min-width: 25rem !default;
$code-editor-typing-area-width: 22rem !default;

// Shadows
$code-editor-box-shadow: $box-shadow-xl !default;

// Paddings
$code-editor-line-numbers-padding-y: 1.25rem !default;
$code-editor-line-numbers-padding-x: 1rem !default;
$code-editor-line-numbers-item-padding-x: 0.25rem !default;
$code-editor-typing-area-padding-y: $code-editor-line-numbers-padding-y !default;
$code-editor-typing-area-padding-x: 1.5rem !default;

// Go To
// =========================================================

// Colors
$go-to-color: $primary !default;
$go-to-hover-color: $white !default;

// Backgrounds
$go-to-bg-color: $primary-soft-bg-color !default;
$go-to-bg-hover-color: $primary !default;

// Borders
$go-to-border-radius: $border-radius !default;

// Type
$go-to-font-size: $btn-icon-font-size !default;

// Width & Heights
$go-to-width: $btn-icon-width !default;
$go-to-height: $go-to-width !default;
$go-to-wave-width: 13.125rem !default;

// Z-Index
$go-to-z-index: 9999 !default;

// Opacity
$go-to-opacity: 0.5 !default;
$go-to-opacity-on-hover: 1 !default;

// Transitions
$go-to-transition: $transition-timing ease-out !default;

// Swiper
// =========================================================

// Colors
$swiper-arrow-icon-color: $gray-700 !default;
$swiper-arrow-hover-color: $primary !default;

// Backgrounds
$swiper-preloader-bg-color: $white !default;
$swiper-arrow-icon-bg-color: $white !default;
$swiper-pagination-bullet-bg-color: transparent !default;
$swiper-pagination-bullet-pseudo-bg-color: $gray-400 !default;
$swiper-pagination-bullet-pseudo-active-bg-color: $primary !default;
$swiper-pagination-bullet-pseudo-hover-bg-color: rgba($primary, 0.5) !default;
$swiper-pagination-light-bullet-pseudo-bg-color: rgba($white, 0.5) !default;
$swiper-pagination-light-bullet-pseudo-hover-bg-color: rgba(
  $white,
  0.5
) !default;
$swiper-pagination-light-bullet-pseudo-active-bg-color: $white !default;
$swiper-pagination-progressbar-bg-color: $overlay-soft-bg-color !default;
$swiper-pagination-progressbar-fill-bg-color: $primary !default;
$swiper-pagination-progress-body-bg-color: $swiper-pagination-progressbar-bg-color !default;
$swiper-pagination-progress-body-helper-bg-color: $swiper-pagination-progressbar-fill-bg-color !default;
$swiper-pagination-progress-light-body-bg-color: rgba($white, 0.5) !default;
$swiper-pagination-progress-light-body-helper-bg-color: $white !default;

// Borders
$swiper-arrow-icon-border-radius: $border-radius-rounded !default;
$swiper-pagination-bullet-border-width: $border-width !default;
$swiper-pagination-bullet-border-color: transparent !default;
$swiper-pagination-bullet-active-border-color: $primary !default;
$swiper-pagination-bullet-hover-border-color: rgba($primary, 0.5) !default;
$swiper-pagination-light-bullet-hover-border-color: $white !default;
$swiper-pagination-light-bullet-active-border-color: $white !default;
$swiper-pagination-bullet-pseudo-border-radius: $border-radius-rounded !default;

// Width & Heights
$swiper-arrow-icon-width: $btn-icon-width !default;
$swiper-arrow-icon-pseudo-width: 1rem !default;
$swiper-arrow-icon-pseudo-height: $swiper-arrow-icon-pseudo-width !default;
$swiper-pagination-bullet-width: 1.5rem !default;
$swiper-pagination-bullet-height: $swiper-pagination-bullet-width !default;
$swiper-pagination-bullet-pseudo-width: 0.25rem !default;
$swiper-pagination-bullet-pseudo-height: $swiper-pagination-bullet-pseudo-width !default;
$swiper-pagination-progress-body-height: 0.25rem !default;

// Type
$swiper-arrow-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$swiper-arrow-icon-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$swiper-arrow-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$swiper-arrow-icon-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$swiper-arrow-prev-icon-hover-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$swiper-arrow-hover-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$swiper-arrow-next-icon-hover-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$swiper-arrow-hover-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$swiper-pagination-fraction-current-font-size: $font-size-base * 4 !default;
$swiper-pagination-fraction-letter-spacing: 0.125rem !default;

// Shadows
$swiper-arrow-icon-box-shadow: 0rem 0.1875rem 0.4375rem 0rem
  rgba($gray-600, 0.4) !default;

// Margins
$swiper-pagination-margin-y: 2rem !default;
$swiper-pagination-bullet-margin-x: 0.25rem !default;
$swiper-pagination-bullet-margin-y: 0.25rem !default;

// Transitions
$swiper-arrow-transition: $btn-transition !default;

// Others
$swiper-arrow-icon-disabled-opacity: 0.5 !default;

// Fancybox
// =========================================================

// Backgrounds
$fancybox-bg-color: $gray-900 !default;
$fancybox-progress-bg-color: $primary !default;

// Others
$fancybox-blur: blur(30px) !default;
