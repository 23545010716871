// Setup
@import "../vendor/bootstrap/scss/functions";

@import "themes/default";
@import "user-variables";
@import "space/variables";

@import "../vendor/bootstrap/scss/variables";
@import "../vendor/bootstrap/scss/utilities";

@import "space/utilities";

// Bootstrap core
@import "../vendor/bootstrap/scss/bootstrap";

/*----------------------------------------------------------------------
  * Space - Multipurpose Responsive Template version v1.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

@import "space/space";

// Custom core
@import "user";
