// user-variables.scss
// Use this to overwrite Bootstrap and Space variables
//

// Example of a variable override to change Space's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: red;
// $font-size-base: 1rem;
$form-switch-input-bg-color: $secondary;
$text-muted: #7f8494;
