/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

tr:hover .eye-icon {
  display: block;
}

.eye-icon {
  margin-left: 5px;
  font-size: 12px;
}

.sitemap-section p {
  margin: 15px 0;
}

.sitemap-section a {
  color: rgb(0, 102, 255);
  font-weight: 500;
  display: block;
  margin: 5px 0;
  padding: 0;
}

.sitemap-section a:hover {
  color: rgb(0, 65, 163);
}

.bottom {
  user-select: none !important;
}

.step .active .step-icon {
  background-color: $secondary;
  font-size: 1rem;
  align-self: start;
}

.accordion-button:not(.collapsed) {
  background-color: #00b4d81a;
  border-radius: 10px 10px 0 0 !important;
  color: #000;
}

.accordion-button {
  font-weight: 900;
  color: #000;
}

.accordion-collapse {
  background-color: #00b4d81a;
  color: #51596c;
  border-radius: 0 0 10px 10px;
}

// .faq-acc .accordion-button:not(.collapsed) {
//   font-weight: 900;
//   background-color: lighten(desaturate($secondary, 50%), 45%);
// }
// .faq-acc .accordion-collapse {
//   background-color: lighten(desaturate($secondary, 50%), 50%);
//   padding-top: 10px;
//   color: #000;
// }

.typed-cursor {
  color: $secondary;
}

.card-case-study {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.4s ease-out;

  .card-img-top {
    border-radius: 0 !important;
    height: 400px;
    background-size: cover;
    position: relative;

    .card-top-logo {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        cursor: pointer !important;
      }

      img {
        width: 50%;
      }
    }
  }

  h5 {
    transition: 0.3s;
  }

  &:hover {
    transform: scale(1.05) !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);

    h5 {
      color: $secondary !important;
    }
  }
}

.job-card {
  gap: 20px;
  padding: 15px 20px;
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid rgb(212, 218, 229);
  border-radius: 12px;
  transition-duration: 0.3s;

  .icon {
    width: 60px;
    height: 60px;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid rgb(212, 218, 229);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    background-color: #fff;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text {
    margin: 0;

    h6 {
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
    }
  }

  &:hover {
    background-color: #f9f9f9;
    transition-duration: 0.3s;
  }
}

.explore-tab {
  background-color: #fff !important;
  border: 2px solid #fff !important;
  font-weight: 800;
  padding: 10px !important;
}

.explore-tab.active {
  border: 2px solid $primary !important;
  color: $primary !important;
}

.explore-tab.active:hover {
  border: 2px solid $primary !important;
  background-color: rgba($primary, 0.2) !important;
}

// Book a card
.bookcard {
  padding: 10px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

// Integrations

//card css
.icon-card {
  margin: 10px 0;
  width: 105px;
  height: 65px;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
}

// Alternate Feature Section.
// CSS for block where test is at left
.feature-back-left {
  height: 100%;
  width: 100vw;
  background-color: rgb(245, 247, 250);
  position: absolute;
  transform: translateX(-30%) scaleY(1.2);
  z-index: 1;

  @media only screen and (max-width: 768px) {
    transform: none;
    background-color: rgb(245, 247, 250);
  }
}

// CSS for block where test is at right
.feature-back-right {
  height: 100%;
  width: 100vw;
  background-color: rgb(245, 247, 250);
  position: absolute;
  transform: translateX(30%) scaleY(1.2);
  z-index: 1;

  @media only screen and (max-width: 768px) {
    transform: none;
    top: -10px;
    background-color: rgb(245, 247, 250);
  }
}

.step-text {
  transition: opacity 0.8s ease-out;
}

.bg-sec-light {
  background-color: lighten($secondary, 50%);
}

.bg-secondary-soft {
  background-color: #f6f5f5;
}

.card-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  img {
    width: 25px;
    height: 35px;
  }

  p {
    font-weight: bold;
    color: #000;
    font-size: 20px;
  }
}

.card-slider {
  border: 1px solid #eee;
}

.line {
  height: 0px;
  padding: 0;
  width: 150%;
  border-top: 1px solid #555;
  z-index: 0;
  position: absolute;
  top: 50%;
}

.nav-pills .nav-link.active {
  background-color: $primary;
  color: white;
}

.nav-pills .nav-link.active:hover {
  background-color: rgba($primary, 0.6);
  color: white;
}

//=========button css==============
.btn {
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-weight: 600;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  border-radius: 12px;
}

.btn.btn-outline-secondary {
  border-color: $secondary;
  color: $secondary !important;
}

.btn.btn-outline-secondary:hover {
  color: $white !important;
}

.btn.btn-outline-primary {
  border-color: $primary;
  color: $primary !important;
}

.btn.btn-outline-primary:hover {
  color: $white !important;
}

.brow-ext-but {
  display: flex;
  align-items: center;
}

.brow-ext-but:hover {
  color: #fff !important;
}

//==============background===========
.bg-light {
  background-color: rgb(245, 247, 250) !important;
}

//==============border-radius===========
.rounded-12 {
  border-radius: 12px !important;
}

//==============Sub Navbar===========
.sub-nav-bar {
  width: 100vw;
  border-bottom: 1px solid rgb(230, 235, 242);
  background-color: $secondary;
}

.sub-nav-bar .links a {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.8px;
  margin-bottom: 10px;
}

//==============Navbar===========
.navbar {
  padding-top: 76px;
}

@media (min-width: 992px) {
  .navbar {
    top: 34px;
  }

  .pagewide {
    height: 34px;
  }

  .navbar-expand-lg .nav-item:not(:last-child) {
    margin-right: 0;
  }
}

.navbar-nav {
  font-size: 14px;
  font-weight: 600;
}

.navbar .navbar-nav .nav-link {
  padding: 1.1rem 0.75rem;
}

.navbar-brand {
  margin-right: 2rem;
}

.navbar-text {
  padding-top: 0.7rem;
}

.nav-vertical.nav-tabs .nav-link.active,
.nav-vertical.nav-tabs .nav-item.show .nav-link {
  color: $secondary;
  border-color: $secondary;
}

//==============Blockquote===========
.blockquote {
  font-size: 0.9rem;
}

.blockquote .quote {
  font-size: 1rem;
  line-height: 1.6;
  font-style: italic;
  color: #555;
}

//==============Blockquote===========
.list-styles {
  font-size: 1rem;
  color: #555;
  margin-left: 0;
  padding-left: 18px;
}

.list-styles li {
  margin-bottom: 10px;
}

.number-highlighter {
  background-color: rgba(3, 4, 94, 0.1);
  height: 56px;
  width: 56px;
  border-radius: 50px;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  font-weight: bold;
}

.text-primary-light-soft {
  color: lighten($primary, 72%);
  font-weight: 300;
}

.text-primary {
  color: #343741 !important;
}

.text-site-primary {
  color: $primary !important;
}

//Pricing ribbon
.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 240px;
  padding: 10px 0;
  background-color: #ffd700;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: $primary;
  font: 700 10px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -40px;
  top: 25px;
  transform: rotate(45deg);
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

// Book CTA Holder
.book-downloader {
  border: 1px solid #e6ebf2;
  border-radius: 12px;
  margin-bottom: 50px !important;
}

.centering-element {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centering-text {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: flex-end;
}

.tabs {
  display: flex;
  margin: 0;
  justify-content: space-around;
  margin-top: 5px;
}

.tabs h7 {
  padding: 10px;
  color: white !important;
}

.tabs h7:hover {
  cursor: pointer;
  background-color: #0AB4D8;
  border-radius: 25px;
}

[data-tab-info] {
  display: none;
}

.active[data-tab-info] {
  display: block;
}

.tabs_text.active {
  cursor: pointer;
  background-color: #0AB4D8;
  border-radius: 25px;
}